export class ExpenseApplicationForm {
  constructor(dom) {
    this.dom = $(dom)
    this.data = $(dom).data('json') || {}
    this.nestedFields = this.dom.find('[data-toggle=nested-field').first()
    this.itemContainer = this.dom.find('[data-field=items]').first()
    this.currencyInput = this.dom.find('[data-input=currency]')
    this.bindEvents()
    this.toggle()
    let _that = this

    this.itemContainer.find('[data-role=field]').each(function(){
      new ExpenseApplicationItemField(_that, $(this))
    })
  }

  bindEvents() {
    let _that = this
    this.dom.find('[data-input=purpose_id]').on('change', function(event){ _that.changePurpose(event) })
    this.dom.find('[data-input=property_period_enabled').on('change', function(event){ _that.changePropertyPeriod(event) })
    this.dom.find('[data-input=custom_purpose').on('change', function(event){ _that.chagneCustomPurpose(event) })
    this.currencyInput.on('change', () => { _that.toggleCurrency() })
    this.nestedFields.on('nested-field:afterAdd', function(e, data){
      new ExpenseApplicationItemField(_that, data.field)
      _that.toggleCurrency()
      _that.toggleDFG()
    })
    this.dom.find('[data-dfg]').each(function(){
      $(this).on('change', function(){ _that.toggleDFG() })
    })
  }

  changePurpose(_event) {
    this.togglePropertyPeriod()
    this.toggleRecurrence()
  }
  changePropertyPeriod(_event) { this.togglePropertyPeriodDate() }
  chagneCustomPurpose(_event) { this.togglePurposeCustomInput() }

  toggle() {
    this.togglePropertyPeriod()
    this.toggleRecurrence()
    this.togglePropertyPeriodDate()
    this.togglePurposeCustomInput()
    this.toggleCurrency()
    this.toggleTotalPrice()
    this.toggleDFG()
  }

  togglePropertyPeriod() {
    this.dom.find('[data-field-group=property_period]').toggle(
      this.getPurposeFeatures().includes('bounded')
    )
  }

  toggleRecurrence() {
    this.dom.find('[data-field-group=expense_recurrence]').toggle(
      this.getPurposeFeatures().includes('recurrent')
    )
  }

  togglePropertyPeriodDate() {
    let checked = this.dom.find('[data-input=property_period_enabled]:checked').val() == 'true'
    this.dom.find('[data-field=property_period_start]').toggle(checked)
    this.dom.find('[data-field=property_period_end]').toggle(checked)
  }

  togglePurposeCustomInput() {
    let checked = this.dom.find('[data-input=custom_purpose]').prop('checked')
    this.dom.find('[data-input=purpose_id]').prop('disabled', checked)
    this.dom.find('[data-field=purpose_id').toggle(!checked)
    this.dom.find('[data-input=purpose_description]').prop('disabled', !checked)
    this.dom.find('[data-field=purpose_description]').toggle(checked)
    if(checked) { this.dom.find('[data-input=purpose_description]').trigger('focus') }
    this.changePurpose()
  }

  toggleCurrency(){
    let currency = this.currencyInput.val()
    if(currency == '') { return }

    let currencyName
    if(this.currencyInput[0].tagName == 'INPUT'){
      currencyName = this.currencyInput.val()
    } else {
      currencyName = this.currencyInput.find(`option[value=${currency}]`).text()
    }

    this.dom.find('[data-value=currency-name]').text(currencyName)
  }

  toggleTotalPrice() {
    let sum = 0
    this.itemContainer.find('[data-role=field] [data-input=price]').each((_, elm) => {
      sum += parseFloat($(elm).val())
    })
    this.dom.find('[data-role=total]').text(sum)
  }

  toggleDFG() {
    let _that = this
    this.dom.find('[data-dfg-input]').each(function(){
      let $el = $(this)
      let inputName = $el.data('dfg-input')
      let targetValue = $el.data('dfg-value')
      let $input = _that.dom.find(`[data-input=${inputName}]`)
      $el.toggle($input.val() == targetValue)
    })
  }

  getPurposeFeatures() {
    let $inputPurposeId = this.getInputDOM('purpose_id')
    let purposeId = $inputPurposeId.val()
    if(purposeId) {
      return $inputPurposeId.find(`option[value=${purposeId}]`).data('features') || []
    } else {
      return []
    }
  }

  getInputDOM(name) {
    return this.dom.find(`[data-input=${name}]:enabled`).first()
  }
}

export class ExpenseApplicationItemField {
  constructor(parent, dom) {
    this.parent = parent
    this.dom = $(dom)
    let _this = this
    this.toggle()
    this.dom.find('[data-input=price]').on('change', ()=> { _this.togglePrice() })
  }
  toggle(){
    this.dom.find('.select2').select2()
  }
  togglePrice(){
    this.parent.toggleTotalPrice()
  }
}