export class TravelApplicationForm {
  constructor(dom) {
    this.dom = $(dom)
    this.inputStayAsOfficialTime = this.dom.find('[data-input=stay_as_official_time]')
    this.inputBuzStartY = this.dom.find('#application_flow_payload_starting_time_1i')
    this.inputBuzStartM = this.dom.find('#application_flow_payload_starting_time_2i')
    this.inputBuzStartD = this.dom.find('#application_flow_payload_starting_time_3i')
    this.inputBuzEndY = this.dom.find('#application_flow_payload_ending_time_1i')
    this.inputBuzEndM = this.dom.find('#application_flow_payload_ending_time_2i')
    this.inputBuzEndD = this.dom.find('#application_flow_payload_ending_time_3i')
    this.inputStartOn = this.dom.find('[data-input=start_on]')
    this.inputEndOn = this.dom.find('[data-input=end_on]')
    this.inputWithPrivateTrip = this.dom.find('[data-input=with_private_trip]')
    this.inputRegion = this.dom.find('[data-input=region]')

    this.fieldRegion = this.dom.find('[data-field=region]')
    this.fieldBookingType = this.dom.find('[data-field=booking_type]')
    this.bindEvents()
    this.toggle()
  }

  bindEvents() {
    let _that = this
    this.inputStayAsOfficialTime.on('change', function(){ _that.toggleStayPeriod() })
    this.inputBuzStartY.on('change', function() { _that.toggleBuzDate() })
    this.inputBuzStartM.on('change', function() { _that.toggleBuzDate() })
    this.inputBuzStartD.on('change', function() { _that.toggleBuzDate() })
    this.inputBuzEndY.on('change', function() { _that.toggleBuzDate() })
    this.inputBuzEndM.on('change', function() { _that.toggleBuzDate() })
    this.inputBuzEndD.on('change', function() { _that.toggleBuzDate() })
    this.inputWithPrivateTrip.on('change', () => { _that.toggleWithPrivateTrip() })
    this.inputRegion.on('change', function() { _that.toggleRegion() })
  }

  toggle() {
    this.updateBuzDate()
    this.toggleStayPeriod()
    this.toggleWithPrivateTrip()
    this.toggleRegion()
  }

  toggleStayPeriod() {
    let isStayAsOfficialTime = this.isStayAsOfficialTime()
    if(isStayAsOfficialTime){ this.copyBuzDate() }
    this.inputStartOn.prop('disabled', isStayAsOfficialTime)
    this.inputEndOn.prop('disabled', isStayAsOfficialTime)
  }

  toggleBuzDate() {
    this.updateBuzDate()
    if(this.isStayAsOfficialTime()){ this.copyBuzDate() }
  }

  toggleWithPrivateTrip() {
    let withPrivateTrip = this.inputWithPrivateTrip.prop('checked')
    this.fieldBookingType.find('input:radio[value=booking_both]').parent('.form-check-inline').toggle(!withPrivateTrip)
    this.fieldBookingType.find('input:radio[value=air_ticket_only]').parent('.form-check-inline').toggle(!withPrivateTrip)

    if(this.fieldBookingType.find('input:checked:visible').length == 0){
      this.fieldBookingType.find('input:radio:visible').first().prop('checked', true)
    }
  }

  toggleRegion() {
    let region = this.fieldRegion.find(':checked').val()
    this.toggleOverseaFields(region != 'domestic')
    this.fieldBookingType.find('input').prop('disabled', region == 'domestic')
  }

  toggleOverseaFields(shawn) {
    this.dom.find('[data-field=with_private_trip]').toggle(shawn)
    this.dom.find('[data-field=booking_type]').toggle(shawn)
  }

  updateBuzDate() {
    let buzStartY = this.inputBuzStartY.val()
    let buzStartM = this.inputBuzStartM.val()
    let buzStartD = this.inputBuzStartD.val()
    let buzEndY = this.inputBuzEndY.val()
    let buzEndM = this.inputBuzEndM.val()
    let buzEndD = this.inputBuzEndD.val()

    this.buzStartDate = moment(`${buzStartY}-${buzStartM}-${buzStartD}`, 'YYYY-MM-DD')
    this.buzEndDate = moment(`${buzEndY}-${buzEndM}-${buzEndD}`, 'YYYY-MM-DD')
  }

  copyBuzDate() {
    this.inputStartOn.val(this.buzStartDate.format('YYYY-MM-DD'))
    this.inputEndOn.val(this.buzEndDate.format('YYYY-MM-DD'))
    // write value to datepick hidden input
    this.dom.find('#application_flow_payload_start_on_root').next('input:hidden').val(this.buzStartDate.format('YYYY-MM-DD'))
    this.dom.find('#application_flow_payload_end_on_root').next('input:hidden').val(this.buzEndDate.format('YYYY-MM-DD'))
  }

  isStayAsOfficialTime() { return this.inputStayAsOfficialTime.prop('checked') }
}