import {Condition} from './flow-editor/Condition';

export class FlowSequenceForm {
  constructor(dom) {
    this.dom = $(dom)
    this.sequenceNestedField = this.dom.find('[data-toggle=nested-field').first()
    this.sequenceContainer = this.dom.find('[data-role=container').first()

    this.sequenceNestedField.on('nested-field:afterAdd', function(_event, data){
      let $field = $(data.field)
      let sequence = new FlowSequence($field)
      $field.data('sequence', sequence)
    })

    this.sequenceContainer.find('[data-role=field]').each((_idx, elm) => {
      let $field = $(elm)
      let sequence = new FlowSequence($field)
      $field.data('sequence', sequence)
    })
  }
}

export class FlowSequence {
  constructor(dom) {
    this.dom = $(dom)
    this.conditionNestedField = this.dom.find('[data-toggle=nested-field]').first()
    this.conditionContainer = this.dom.find('[data-role=conditions-container]').first()
    this.conditionNestedField.nestedField()
    this.conditionNestedField.on('nested-field:afterAdd', function(e, data){
      let $field = $(data.field)
      let cond = new Condition($field)
      $field.data('condition', cond)
    })
    this.conditionContainer.find('[data-role=field]').each((idx, elm) => {
      let $elm = $(elm)
      $elm.data('cond', new Condition($elm))
    })
  }
}