export function changeAmountInCurrency(e) {
  let $source = $(e.currentTarget)
  let $inField = $source.closest('[data-role=field]')
  if($inField.length == 0) { return } // Support in nested field only now.

  let amountInCurrency = parseFloat($source.val())
  let sourceInputName = $source.data('input')
  let currencyName = $inField.find('[data-input=currency]').val() // or data-role?
  if(!currencyName && $source.data('currency')) { currencyName = $source.data('currency') }

  let exchangeRateMapping = $(document).find('[data-input=currency][data-json]').data('json') || {}
  let exchangeRate = exchangeRateMapping[currencyName] || 1

  let targetInputName = sourceInputName.replace('_in_currency', '')
  let target = $inField.find(`[data-input=${targetInputName}]`)
  let amount = amountInCurrency * exchangeRate
  target.val(amount)
}

export function changeCurrencyInRow(e) {
  let $source = $(e.currentTarget)
  let $inField = $source.closest('[data-role=field]')
  if($inField.length == 0) { return } // Support in nested field only now.

  let currencyName = $source.val()
  let amountInCurrency = $inField.find('[data-amount=inCurrency]')
  if(amountInCurrency.length == 0) { return }
  let sourceInputName = amountInCurrency.data('input')
  let targetInputName = sourceInputName.replace('_in_currency', '')

  let exchangeRateMapping = $(document).find('[data-input=currency][data-json]').data('json')
  let exchangeRate = exchangeRateMapping[currencyName] || 1

  let target = $inField.find(`[data-input=${targetInputName}]`)
  let originValue = parseFloat(amountInCurrency.val())
  let amount = originValue * exchangeRate
  target.val(amount)
}

$.fn.exchangeRate = function() {
  // change value of amount_in_currency
  $(this).on('change currency:change', '[data-role=inCurrency]', changeAmountInCurrency)

  // change currency in row
  $(this).on('change currency:change', '[data-role=inRowCurrency]', changeCurrencyInRow)

  // TODO: change currency of all, how to resolve conflict of row level?
}