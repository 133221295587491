class DynamicDropdown {
  constructor(dom) {
    this.domSelector = $(dom)
    this.sourceUrl = this.domSelector.data('url')
    this.target = this.domSelector.data('target')
    this.targetSeletor = $(document).find(this.target)
    this.bindEvents()
  }

  bindEvents() {
    this.domSelector.on('change', (function(_this) {
      return function() {
        _this.fetchDropdownItems()
      }
    })(this))
  }

  fetchDropdownItems() {
    var optionSourceUrl = $('option:selected', this.domSelector).data('url')
    url = optionSourceUrl || this.sourceUrl
    if (url != undefined) {
      $.ajax({
        url: url,
        success: (function(_this) {
          return function(results) {
            data = results['resources'].map(result =>
              ({ id: result['id'], text: result['name'] })
            )
            _this.targetSeletor.empty().select2({ data: data })
          }
        })(this)
      })
    }
  }
}

$.fn.dynamicDropdown = function() {
  $(this).each(function(_i, el) {
    initialized = $(el).data('instance')
    if (initialized) return

    obj = new DynamicDropdown(el)
    $(el).data('instance', obj)
  })
}
