export class PolymForm {
  constructor(dom) {
    this.dom = $(dom)
    let that = this
    let conditionData = $(dom).data('dynamic-rules') || []
    this.dynamicRules = $.map(conditionData, function(rule, _index) { return new DynamicRule(that, rule)})
    this.dom.find('[data-toggle=group-field]').groupField()
    this.dom.on('gf:afterAdd', '[data-toggle=group-field]', (event, data) => {
      that.toggleWithin($(data.field))
      that.toggleAll()
    })
    this.dynamicTriggeredFields = []
    this.dynamicRuleCounter = {}
    $(this.dynamicRules).each((_idx, rule) => { that.dynamicRuleCounter[rule.ruleData.field_id] = 0 })

    this.toggleAll()
    this.toggleByField(this.dom)
  }

  toggleAll() {
    this.dynamicRules.forEach(rule => rule.trigger())
  }

  toggleByField(dom) {
    dom.find('[data-field-type=date]').each(function(_idx, elm){
      let $target = $(elm)
      if($target.prop('readonly')) { return }
      let options = { format: 'yyyy-mm-dd', formatSubmit: 'yyyy-mm-dd', container: '#field-datepicker' }
      if($target.data('date-field-max') != undefined) { options.max = $target.data('date-field-max') }
      if($target.data('date-field-min') != undefined) { options.min = $target.data('date-field-min') }
      $target.pickdate(options)
    })
  }

  toggleWithin(dom) {
    dom.find('select.select2').select2()
    // Todo: Separate selector from global script. and support metadata option feild by field
    dom.find('.datepicker').pickdate(
      {
        format: 'yyyy-mm-dd',
        formatSubmit: 'yyyy-mm-dd'
      }
    )

    this.toggleByField(dom)
  }

  resetTriggeredFields() {
    $(this.dynamicTriggeredFields).each((_idx, elm) => {
      $(elm).removeData('toggleShown')
    })
  }

  // TODO: Just keep part of code written by origin author, check and refcator later
  // getFieldDoms(field_ids){
  //   let search_pattern = field_ids.map(id => `[data-field=${id}]`).join(',')
  //   return this.dom.find(search_pattern)
  // }

  // triggerAction(doms, action){
  //   if(this.allowActions.includes(action)){
  //     action === 'show' ? doms.forEach(dom => dom.show()) : doms.forEach(dom => dom.hide())
  //   }
  // }

  // bindEvent(data) {
  //   let _that = this
  //   let observedFieldDom = new FieldDom(this.getFieldDoms([data.field_id]))
  //   let targetDoms = $.map(this.getFieldDoms(data.target_field_ids), function(element, index) { return new FieldDom(element); });
  //   observedFieldDom.inputFields().on('change', function(){
  //     debugger
  //     if(observedFieldDom.checkCondition(data.value, data.operator)){
  //      _that.triggerAction(targetDoms, data.action)
  //     }else{
  //      let reverseAction = data.action == 'show' ? "hide" : "show";
  //      _that.triggerAction(targetDoms, reverseAction)
  //     }
  //   })
  // }

  // getFieldDomByInput(input_dom){
  //   return new FieldDom(input_dom.closest('[data-field]'))
  // }
}

export class DynamicRule {
  constructor(form, ruleData) {
    this.form = form
    this.ruleData = ruleData
    this.counter = 0
    // TODO: determine trigger by diffeent type in the future
    this.triggerAction = new TogglerAction(form ,ruleData)
    this.targetFieldSelectors = this.getTargetFieldSelectors()
    this.triggerFieldSelector = `[data-field=${ruleData.field_id}]`
    let triggerInputSelector = `[data-input=${ruleData.field_id}]`
    let that = this

    $(form.dom).on('change', triggerInputSelector, function(){ that.trigger() })
  }

  getTargetFieldSelectors(element = '') {
    return $.map(this.ruleData.target_field_ids, (elm, _idx) => `${element}[data-dfg=${elm}]` ).join(',')
  }

  trigger() {
    this.counter++
    if(this.form.dynamicRuleCounter[this.ruleData.field_id] < this.counter) {
      // First triggered rule of current event
      this.form.dynamicRuleCounter[this.ruleData.field_id] = this.counter
      this.form.resetTriggeredFields()
    }

    let $triggerField = this.form.dom.find(this.triggerFieldSelector)
    if($triggerField.length > 1) {
      this.triggerWithNestedFields()
    } else {
      let field = new FieldDom($triggerField)
      let isMatched = field.checkCondition(this.ruleData.value, this.ruleData.operator)
      let targets = this.form.dom.find(this.targetFieldSelectors)
      this.triggerAction.fire(isMatched, targets)
    }
  }

  triggerWithNestedFields() {
    let triggers = []
    let that = this
    let anyShow = false
    $(this.form.dom).find('[data-role=field]').each((_idx, fieldDom) => {
      let $triggerFields = $(fieldDom).find(this.triggerFieldSelector)
      $triggerFields.each((_j, elm) => {
        let field = new FieldDom($(elm))
        let isMatched = field.checkCondition(that.ruleData.value, that.ruleData.operator)
        triggers.push([isMatched, $(fieldDom)])
        if(that.triggerAction.toShowValue(isMatched)){ anyShow = true }
      })
    })

    for(var trigger of triggers){
      let targets = trigger[1].find(that.getTargetFieldSelectors('td'))
      that.triggerAction.innerFire(trigger[0], targets, anyShow)
    }

    // trigger thead columns
    let thead = this.form.dom.find(this.triggerFieldSelector).first().closest('table').find('[data-role=thead]')
    let targets = thead.find(that.targetFieldSelectors)
    if(anyShow) {
      that.triggerAction.fire(that.triggerAction.toMatchedValue(true), targets)
    } else {
      that.triggerAction.fire(that.triggerAction.toMatchedValue(false), targets)
    }
  }
}

export class TogglerAction {
  constructor(form, ruleData) {
    this.form = form
    this.action = ruleData.action
  }

  fire(isMatched, targets){
    this.toggle(targets, this.toShowValue(isMatched))
  }

  toShowValue(isMatched){
    if(this.action == 'show') {
      return isMatched
    } else {
      return !isMatched
    }
  }

  toMatchedValue(shown){
    if(this.action == 'show') {
      return shown
    } else {
      return !shown
    }
  }

  toggle(targets, shown) {
    let that = this
    $(targets).each((_idx, target) => {
      if(!shown && $(target).data('toggleShown')) { return }
      $(target).toggle(shown)
      $(target).find('input, textarea, select').prop('disabled', !shown)
      $(target).data('toggleShown', shown)
      that.form.dynamicTriggeredFields.push(target)
    })
  }

  innerFire(isMatched, targets, anyShow){
    if(this.action == 'show') {
      this.innerToggle(targets, isMatched, anyShow)
    } else {
      this.innerToggle(targets, !isMatched, anyShow)
    }
  }

  innerToggle(targets, shown, anyShow) {
    let that = this
    $(targets).each((_idx, target) => {
      if(!shown && $(target).data('toggleShown')) {  return }

      $(target).toggle(anyShow)
      $(target).find('> div').toggle(shown)
      $(target).find('input, textarea, select').prop('disabled', !shown)
      $(target).data('toggleShown', shown || anyShow)
      that.form.dynamicTriggeredFields.push(target)
    })
  }
}

// TODO: Just keep part of code written by origin author, check and refcator later
export class FieldDom {
  constructor(dom){
    this.dom = $(dom)
    this.allowOperators = ['Equal', 'NotEqual', 'Include', 'Exclude']
    this.arrayOperators = ['Include', 'Exclude']
    this.delayMilliSecond = 300
  }

  inputFields(){
    return this.dom.find('input, select, textarea')
  }

  isHidden(){
    return this.dom.css("display") === "none"
  }

  show(){
    if(!this.isHidden()){
      return true
    }
    this.dom.show(this.delayMilliSecond)
    let input = this.inputFields()
    input.prop('disabled', false)
    setTimeout(function() { input.trigger( "change" ); }, this.delayMilliSecond + 100)
  }

  hide(){
    if(this.isHidden()){
      return true
    }
    this.dom.hide(this.delayMilliSecond)
    let input = this.inputFields()
    input.prop('disabled', true)
    setTimeout(function() { input.trigger( "change" ); }, this.delayMilliSecond + 100)
  }

  val(){
    if(this.isSelector()){
      return this.dom.find("option:selected").val()
    }else if(this.isCheckboxOrRadiobutton()){
      var values = []
      this.dom.find("input:checked").each(function () {
        let value = $(this).val() || 0
        values.push(value) ;
      });
      return values.length === 1 ? values[0] : values
    }else{
      return this.inputFields().val()
    }
  }

  checkCondition(target_value, operator){
    if(this.isHidden()){
      switch(operator){
        case 'NotEqual': case 'Exclude':
          return true
        default:
          return false
      }
    }
    let current_value = this.val()
    if(!this.allowOperators.includes(operator) || Array.isArray(target_value) && !this.arrayOperators.includes(operator)){
      throw 'Operator Error please set the right operator for target_value';
    }

    if(typeof(current_value) === 'number'){ current_value = current_value.toString() }

    let checker = new ValueChecker(target_value, current_value)
    return checker[`is${operator}`]()
  }

  isSelector(){
    return this.inputFields().is('select')
  }

  isCheckboxOrRadiobutton(){
    return this.dom.find('input:radio, input:checkbox').length > 0
  }
}

// TODO: Just keep part of code written by origin author, check and refcator later
export class ValueChecker {
  constructor(target_value, actual_value) {
    this.target_value = target_value
    this.actual_value = actual_value
  }

  isEqual(){
    // not equal if type not the same
    if(this.targetStringActualArray() && this.targetArrayActualString()) {
      return false
    } else if(this.bothString()) {
      return this.target_value === this.actual_value
    } else if(this.bothArray()) {
      return JSON.stringify(this.target_value) == JSON.stringify(this.actual_value)
    }
    return false
  }

  isNotEqual(){
    return !this.isEqual();
  }

  isInclude(){
    // string can't inlcude string or array
    if(this.targetStringActualArray() || this.bothString()) {
      return false
    } else if(this.targetArrayActualString()) {
      return this.target_value.includes(this.actual_value)
    } else if(this.bothArray()) {
      return this.target_value.every(v => this.actual_value.includes(v))
    }
    return false
  }

  isExclude(){
    return !this.isInclude();
  }

  bothArray(){
    return Array.isArray(this.target_value) && Array.isArray(this.actual_value)
  }

  bothString(){
    return typeof(this.target_value) === 'string' && typeof(this.actual_value) === 'string'
  }

  targetStringActualArray(){
    return typeof(this.target_value) === 'string' && Array.isArray(this.actual_value)
  }

  targetArrayActualString(){
    return Array.isArray(this.target_value) && typeof(this.actual_value) === 'string'
  }
}
