// import {Condition} from './Condition';
import {FieldMetadataForm} from './FieldMetadataForm';

export class FormEditorForm {
  constructor(dom) {
    this.dom = $(dom)
    this.form = this.dom.find('[data-role=form]')
    this.formId = this.dom.data('id')
    this.fieldMetadataUrl = this.dom.data('field-url')
    this.formPageToggler = this.dom.find('[data-role=form-pages]')
    this.menuContainer = this.dom.find('[data-role=menu-container]')
    this.fieldContainer = this.dom.find('[data-role=field-container]')
    this.bindEvents()
    this.refreshDragAndDrop()
    let $fieldEditor = this.dom.find('[data-role=field-editor]').first()
    this.fieldEditor = new FieldMetadataForm(this, $fieldEditor)

    this.toggleMenuFields()
    this.setupMenu()
  }

  setupMenu(){
    // Makr draggable / droppable / sortable
    let that = this
    this.dom.find('[data-role=menu-container] [data-menu=normal-field] li:not(.in-use)').draggable({
      connectToSortable: '[data-role=field-container]',
      revert: 'invalid',
      cursor: 'move',
      helper: "clone"
    })

    this.dom.find('[data-role=menu-container] [data-menu=composite-field]:not(.locked)').each(function(_, elm){
      let parent_id = $(elm).data('id')
      $(elm).find('li:not(.in-use)').draggable({
        connectToSortable: `[data-role=form-field][data-id=${parent_id}] [data-role=nested-field-container]`,
        revert: 'invalid',
        cursor: 'move',
        helper: "clone"
      })
      let droppableSelector = `[data-role=form-field][data-id=${parent_id}] [data-role=nested-field-container]`
      let droppable = that.dom.find(droppableSelector)
      that.bindDroppable(droppable, droppableSelector)
    })
  }

  toggleMenuFields() {
    let that = this
    this.form.find('[data-role=form-field]').each(function(_idx, elm){
      let fieldId = $(elm).data('id')
      that.menuContainer.find(`[data-role=menu-item][data-id=${fieldId}]`).addClass('in-use')
    })
  }

  bindEvents() {
    let that = this
    this.formPageToggler.on('nested-field:afterAdd', function(_e, data) {
      that.bindDroppable(data.field.find('[data-role=field-container]'))
    })

    this.dom.on('click', '[data-role=form-field] [data-editor-action=delete]', function(e) {
      let $field = $(e.currentTarget).closest('[data-role=form-field]')
      that.removeField($field)
    })

    this.dom.on('click', '[data-role=form-field] [data-editor-action=edit]', function(e) {
      let $field = $(e.currentTarget).closest('[data-role=form-field]')
      that.editField($field)
    })

    this.form.on('submit', function(e) { that.onSubmit(e) })

    this.dom.find('#formEditorModal').on('shown.bs.modal', (e) => {
      $(e.currentTarget).find('[data-toggle=simple-toggle]').trigger('change')
    })
  }

  removeField($field) {
    $field.fadeOut(function(){$field.remove()})
    let fieldId = $field.data('id')
    this.menuContainer.find(`[data-role=menu-item][data-id=${fieldId}]`).removeClass('in-use')
    this.setupMenu()
  }

  editField($field) {
    this.fieldEditor.load($field)
    this.fieldEditor.open()
  }

  refreshField($field, content) {
    let partial = $(content).find('[data-role=form-field]')[0].outerHTML
    $field.replaceWith(partial)
  }

  extractFieldData(form_field) {
    let $ff = $(form_field)
    let field_id = $ff.data('id')
    let metadata = $ff.data('json')
    let that = this
    metadata.field_id = field_id

    let subFields = $ff.find('[data-role=nested-field-container] > [data-role=form-field]')
    let subFormFields = {}
    if(subFields.length > 0) {
      subFields.each(function(x, sub_form_field){
        subFormFields[x] = that.extractFieldData(sub_form_field)
      })
      metadata.form_fields = subFormFields
    }

    return metadata
  }

  onSubmit(event) {
    event.preventDefault()
    let $form = this.dom.find('[data-role=form]').first()
    let url = $form.attr('action')
    let data = {}
    let translations = {}
    let method = $form.find('input[name=_method]').val() || 'POST'
    let that = this

    $form.find('[data-role=field].form-page').each(function(index, elm){
      let form_fields = {}
      $(elm).find('[data-role=field-container] > [data-role=form-field]').each(function(j, form_field){
        form_fields[j] = that.extractFieldData(form_field)
      })
      data[index] = { form_fields: form_fields }
    })

    $form.find('[data-translation-locale]').each((_idx, elm) => {
      let $elm = $(elm)
      let key = `${$elm.data('translation-field')}_${$elm.data('translation-locale')}`
      translations[key] = $(elm).val()
    })

    let recordType = $form.find('input#type').val()
    let tagList = $form.find('input#form_tag_list').val()
    $.ajax({
      type: method,
      url: url,
      data: { form: { form_pages: data, translations: translations, tag_list: tagList }, type: recordType },
      dataType: 'script',
      complete: function(res) {
        that.form.find('[data-button=submit]').prop('disabled', false)
      }
    })
  }

  onDrop(event, ui) {
    if(ui.item.data('role') == 'menu-item') {
      let field = ui.item
      let content = field.data('field')
      field.replaceWith(content)

      let fieldId = $(content).data('id')
      let that = this
      setTimeout(function(){ that.afterDroppedField(fieldId) }, 1000)
    }
  }

  afterDroppedField(fieldId) {
    let droppableSelector = `[data-role=form-field][data-id=${fieldId}] [data-role=nested-field-container]`
    let droppable = this.dom.find(droppableSelector)
    this.bindDroppable(droppable, droppableSelector)
    this.menuContainer.find(`[data-role=menu-item][data-id=${fieldId}]`).addClass('in-use')
  }

  bindDroppable($droppables, connectToSelector = '[data-role=field-container]') {
    let that = this
    $droppables.sortable({
      revert: true,
      stop: function(event, ui) { that.onDrop(event, ui) }
    }).droppable();

    $droppables.find('[data-role=form-field]').draggable({
      connectToSortable: connectToSelector,
      revert: 'invalid',
      helper: 'original'
    })
  }

  refreshDragAndDrop() {
    let droppables = this.dom.find('[data-role=field-container]')
    this.bindDroppable(droppables)
  }
}