export function bindComment($input){
  $input.on('click', '[comment-action=edit]', function(e) { toggleEdit(e) })
  toggleForm()

  function toggleEdit(event) {
    event.preventDefault()
    toggleForm()
    toggleComment()
  }

  function toggleForm() {
    $input.find('[data-toggle=comment-edit-form]').toggle()
  }

  function toggleComment() {
    $input.find('[data-toggle=comment-edit-show]').toggle()
  }
}

$.fn.comment = function() {
  $(this).each(function(_i, el) {
    let $el = $(el)
    if($el.data('comment')) return;

    bindComment($el)
    $el.data('comment', true)
  })
}