export class PaymentRemittanceField {
  constructor(dom) {
    this.dom = $(dom)
    this.overseaFields = ['vendor_address', 'bank_address', 'business_identifier_code']
    this.vendorDataFields = ['vendor_name', 'bank_name', 'bank_branch_name', 'account_name', 'account_number']

    this.inputVendorBankAcountId = this.dom.find('[data-input=vendor_bank_account_id]')
    this.inputCustomVendorData = this.dom.find('[data-input=custom_vendor_data]')
    this.inputOversea = this.dom.find('[data-input=oversea]')
    this.bindEvents()
    this.toggle()
    this.mode = 'vendor'
  }

  pettyCashMode() {
    this.mode = 'pettyCash'
    this.toggleVendorMode(false)
    let data = this.dom.find('[data-toggle=payment_method]').data('applier')
    this.dom.find('input[data-input=vendor_name]').val(data.vendor_name)
    this.dom.find('input[data-input=bank_name]').val(data.bank_name)
    this.dom.find('input[data-input=bank_branch_name]').val(data.bank_branch_name)
    this.dom.find('input[data-input=account_name]').val(data.account_name)
    this.dom.find('input[data-input=account_number]').val(data.account_number)
  }

  vendorMode(){
    this.mode = 'vendor'
    this.toggleVendorMode(true)
  }

  toggleVendorMode(shown) {
    let vendorModeFields = ['vendor_bank_account_id', 'vendor_attachment', 'custom_vendor_data', 'vendor_name', 'oversea']
    let fieldSelectors = this.getFieldSelector(vendorModeFields, 'field')
    let inputSelectors = this.getFieldSelector(vendorModeFields, 'input')
    this.dom.find(fieldSelectors).toggle(shown)
    this.dom.find(inputSelectors).prop('disabled', !shown)
  }

  bindEvents() {
    let that = this
    this.inputVendorBankAcountId.on('change', () => { that.toggleVendorBankAccount() })
    this.inputCustomVendorData.on('change', () => { that.toggleReadonly() })
    this.inputOversea.on('change', () => { that.toggleOverseaFields() })
  }

  toggle(){
    this.toggleOverseaFields()
    this.toggleCustomField()
    this.toggleReadonly()
  }

  toggleOverseaFields() {
    let isOversea = this.inputOversea.prop('checked')
    let selectors = this.getFieldSelector(this.overseaFields)
    this.dom.find('[data-field-group=oversea]').toggle(isOversea)
    this.dom.find(selectors).prop('disabled', !isOversea)
  }

  toggleReadonly() {
    let isReadOnly

    if(this.mode == 'pettyCash'){
      isReadOnly = true
    } else {
      let isCustomVendorData = this.inputCustomVendorData.prop('checked')
      this.dom.find('[data-field-group=vendor_attachment]').toggle(isCustomVendorData)
      this.inputOversea.prop('disabled', !isCustomVendorData)
      isReadOnly = !isCustomVendorData
    }
    let selectors = this.getFieldSelector(this.vendorDataFields, 'input')
    this.dom.find(selectors).prop('readonly', isReadOnly)
  }

  toggleCustomField() {
    if(!this.inputVendorBankAcountId) { return ; }
    $('[data-field=custom_vendor_data]').toggle(this.inputVendorBankAcountId.val() != '')
  }

  toggleVendorBankAccount() {
    if(!this.inputVendorBankAcountId) { return ; }

    this.toggleCustomField()

    let vendorId = this.inputVendorBankAcountId.val()
    if(vendorId == '') {
      let selectors = this.getFieldSelector(this.vendorDataFields, 'input')
      this.dom.find(selectors).val('')

      this.inputOversea.prop('checked', false).trigger('change')
      this.inputCustomVendorData.prop('checked', true).trigger('change')
      return
    }

    let option = this.inputVendorBankAcountId.find(`option[value=${vendorId}]`).first()
    if(!option) { return }

    let data = option.data('json')
    this.dom.find('input[data-input=custom_vendor_data]').prop('checked', false).trigger('change')
    this.dom.find('input[data-input=vendor_name]').val(data.vendor_name)
    this.dom.find('input[data-input=bank_name]').val(data.bank_name)
    this.dom.find('input[data-input=bank_branch_name]').val(data.bank_branch_name)
    this.dom.find('input[data-input=account_name]').val(data.account_name)
    this.dom.find('input[data-input=account_number]').val(data.account_number)
    this.dom.find('input[data-input=oversea]').prop('checked', data.oversea).trigger('change')
    this.dom.find('input[data-input=vendor_address]').val(data.vendor_address)
    this.dom.find('input[data-input=bank_address]').val(data.bank_address)
    this.dom.find('input[data-input=business_identifier_code]').val(data.business_identifier_code)
  }

  getFieldSelector(fieldNames, type = 'field') {
    return $.map(fieldNames, name => `[data-${type}=${name}]`).join(',')
  }
}

export class CustomPaymentDateField {
  constructor(form, dom) {
    this.form = form
    this.dom = $(dom)
    this.inputSpecifyPaymentDate = this.dom.find('[data-input=specify_payment_date]')
    this.minimalPaymentDate = this.dom.data('payment-date-minimum')
    this.fieldDate = this.dom.find('[data-field=payment_date]')
    this.fieldReason = this.dom.find('[data-field=payment_date_reason]')
    this.roleDefaultDate = this.dom.find('[data-role=default_payment_date]')
    this.dom.find('.payment_date').pickdate(
      {
        format: 'yyyy-mm-dd',
        formatSubmit: 'yyyy-mm-dd',
        min: this.minimalPaymentDate
      }
    )

    this.bindEvents()
    this.toggle()
  }

  bindEvents() {
    let _this = this
    this.inputSpecifyPaymentDate.on('change', () => { _this.toggleSpecifyPaymentDate() })
  }

  toggle() {
    let code = this.form.getCurrentPaymentMethodCode()
    if (code == ''){ return this.dom.toggle(false) }

    this.dom.toggle(true)

    if(code == 'credit_card') {
      this.dom.find('[data-field=specify_payment_date]').hide()
      this.fieldDate.show()
      this.fieldReason.hide()
      this.roleDefaultDate.hide()
    } else {
      this.toggleSpecifyPaymentDate()
    }
  }

  toggleSpecifyPaymentDate() {
    let shown = this.inputSpecifyPaymentDate.prop('checked')

    this.fieldDate.toggle(shown)
    this.fieldReason.toggle(shown)
    this.roleDefaultDate.toggle(!shown)
  }
}