export class Condition {
  constructor(dom) {
    this.dom = $(dom)
    this.selectProperty = this.dom.find('[data-input=property]')
    this.blockInputValue = this.dom.find('[data-toggle=input_value]')
    this.selectInputValue = this.blockInputValue.find('select')
    this.blockInquiryValue = this.dom.find('[data-toggle=inquiry_value]')
    this.toggle()

    const _this = this
    this.selectProperty.on('change', () => {
      _this.toggleValueInput()
      _this.togglePropertyInput()
    })
  }
  toggle(){
    this.togglePropertyInput()
    this.toggleValueInput()
    this.toggleSelectedValue()
  }

  togglePropertyInput() {
    let property = this.selectProperty.val()
    if(property == '' || property == 'inquiry') { return }
    this.selectInputValue.empty()
    let data = gon.assets[property]
    for(let x in data) {
      let datum = data[x]
      let option = $(`<option value="${datum.id}">${datum.name}</option>`)
      this.selectInputValue.append(option)
    }
  }

  toggleValueInput() {
    let property = this.selectProperty.val()
    let showInputValue = (property != '' && property != 'inquiry')
    this.blockInputValue.toggle(showInputValue)
    this.blockInquiryValue.toggle(property == 'inquiry')
  }

  toggleSelectedValue(){
    let selected = String(this.blockInputValue.data('selected'))
    if(selected == '') { return }
    let values = selected.split(',')
    let _that = this
    values.forEach(element => {
      _that.selectInputValue.find(`option[value=${element}]`).prop('selected', true)
    })
  }
}