import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { offset: Number }

  to({ params }) {
    if($(`${params.anchor}`).length == 0) { return }
    let position = $(`${params.anchor}`).offset().top - this.offsetValue
    $([document.documentElement, document.body]).animate({ scrollTop: position }, 600);
  }

  _updateStack(batchId, html) {
    let $element = $(this.applicationFlowBundlesTarget).find(`#ReimbursementStack_${batchId}`)
    if($element.length > 0){
      $element.replaceWith(html)
    } else {
      let $container = $(this.applicationFlowBundlesTarget).find('[data-role=container]')
      $container.find('.blank-state').remove()
      $container.prepend(html)
    }
  }
}