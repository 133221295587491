export class FieldForm {
  constructor(dom) {
    this.dom = dom
    this.bindEvents()
    this.toggle()
  }

  bindEvents() {
    let that = this
    this.dom.find('[data-input=option_source]').on('change', () => that.toggleOptionSource())
    this.dom.find('[data-toggle=nested-field]').on('nested-field:afterAdd', function(e, data){
      let $field = $(data.field)
      $field.find('.select2').select2()
    })
  }

  toggle() {
    this.toggleOptionSource() // Selector
  }

  toggleOptionSource() {
    let input = this.dom.find('[data-input=option_source]')
    if(input.length == 0){ return; }

    let value = input.val()
    debugger
    let list = this.dom.find('[data-group=custom-options]')
    list.toggle(value == 'custom')
  }
}