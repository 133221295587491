import '@fortawesome/fontawesome-free/js/all'
import 'bootstrap'
import 'jquery'
import 'select2'

import './settings'
import './utils'
import "./trix-editor-overrides"
import './polym'
import * as forms from './forms/'

$(document).on('turbolinks:load', function() {
  $('[data-toggle=nested-field').nestedField();
  $('[data-toggle=comment').comment();
  $('[data-toggle=simple-switch').on('change', (e) => {
    let $switcher = $(e.currentTarget)
    let enabled = $switcher.prop('checked')
    let $target = $($switcher.data('target'))
    let style = $switcher.data('style') || 'switch-on'
    $target.toggleClass(style, enabled)
  })
  $('.datepicker').pickdate(
    {
      format: 'yyyy-mm-dd',
      formatSubmit: 'yyyy-mm-dd'
    }
  )
  $('[data-toggle="popover"]').popover()
  $('.datepicker-future').pickdate(
    {
      format: 'yyyy-mm-dd',
      formatSubmit: 'yyyy-mm-dd',
      min: true
    }
  )
  $('input.receipt-date').pickdate(
    {
      format: 'yyyy-mm-dd',
      formatSubmit: 'yyyy-mm-dd',
      max: true,
    }
  )
  $('select.select2').select2()
  $('[data-toggle=dynamic-dropdown]').dynamicDropdown()
  $('[data-toggle=simple-summer]').simpleSummer();

  $('[data-form]').each(function(idx){
    let $el = $(this)
    let formName = $(this).data('form')
    let formClass = forms[`${formName}Form`]
    if(formClass && !$el.data('instance')) {
      let form = new formClass($el)
      $el.data('instance', form)
    }
  })
  $('[data-toggle=modal]').on('click', (e) => {
    e.preventDefault()
    $($(e.currentTarget).data('target')).modal('show')
  })

  $(document).on('click', '.scrl.mask.on', (e) => {
    $(e.currentTarget).removeClass('on').addClass('off')
  })
  $(document).on('click', '.scrl.mask.off', (e) => {
    $(e.currentTarget).removeClass('off').addClass('on')
  })

  $(document).on('change', '[data-role=direct-uploader] [data-toggle=re-upload]', (e) => {
    let checked = $(e.currentTarget).prop('checked')
    let $uploader = $(e.currentTarget).closest('[data-role=direct-uploader]')
    $uploader.toggleClass('re', checked)
    $uploader.find('input[type=file]').prop('disabled', !checked)
  })

  $(document).on('click', '[data-toggle=anchor]', (e) => {
    let $target = $(e.currentTarget)
    console.log($target)
    let href = $target.prop('href').toString()
    let anchor = href.split('#')[1]
    if(anchor) {
      anchor = `#${anchor}`
    } else {
      anchor = $target.data('target')
    }

    let $to = $(anchor)
    if($to.length > 0){
      e.preventDefault();
      $('html, body').stop().animate({
        scrollTop: $to.offset().top
      }, 1000);
    }
  })

  $(document).on('change', '[data-toggle=simple-toggle]', (e) => {
    let selector = $(e.currentTarget).data('target')
    let $target = $(document).find(selector)
    $target.toggle($(e.currentTarget).prop('checked'))
  })

  $(document).exchangeRate()
  $('[data-toggle=activenode]').each(function(idx, elm){
    let target = $(elm).data('target')
    $(`[data-activenode=${target}]`).addClass('active')
  })

  $(document).on('click', '[data-toggle=clearform]', function(e){
    let selector = $(e.currentTarget).data('target')
    $(selector).val('').trigger('change')
  })

  $(document).on('click', '[data-toggle=listFilter]', function(e){
    e.preventDefault();
    let $anchor = $(e.currentTarget)
    let listName = $anchor.data('list')
    let filterType = $anchor.data('filter')
    let value = $anchor.data('value')
    $(`[data-role=${listName}List] [data-role=${listName}ListItem]`).hide()
    $(`[data-role=${listName}List] [data-role=${listName}ListItem][data-${filterType}=${value}]`).show()
    $anchor.parents('[data-role=listFilterContainer]').find('[data-toggle=listFilter]').removeClass('active')
    $anchor.addClass('active')
  })
})

$(document).on('turbolinks:before-cache', function(){     // this approach corrects the select 2 to be duplicated when clicking the back button.
  $('select.select2').select2('destroy')
});