import './relation_fields'
import {PolymForm} from './PolymForm'

$(document).on('turbolinks:load', function() {
  $('[data-polym=form]').each(function(_){
    let $dom = $(this)
    if(!$dom.data('polym-init')){
      let form = new PolymForm($dom)
      $dom.data('polym-init', form)
    }
  })
})