import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'applicationFlowBundles'
  ]

  initialize(){
    $(this.element).on('dashboard.reimbursement.insert', (_e, data) => {
      this.reimbursementStack({ params: { batchId: data.reimbursement.batch_id }})
    })
  }

  reimbursementStack({ params }) {
    let url = `/dashboard/reimbursements/${params.batchId}`
    $.get(url, (html) => {
      this._updateStack(params.batchId, html)
    }, 'html')
  }

  _updateStack(batchId, html) {
    let $element = $(this.applicationFlowBundlesTarget).find(`#ReimbursementStack_${batchId}`)
    if($element.length > 0){
      $element.replaceWith(html)
    } else {
      let $container = $(this.applicationFlowBundlesTarget).find('[data-role=container]')
      $container.find('.blank-state').remove()
      $container.prepend(html)
    }
  }
}