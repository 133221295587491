class SimpleSummer {
  constructor(element) {
    this.dom = $(element)
    this.selector = this.dom.data('source')
    this.bindEvent()
  }

  refresh() {
    let sum = 0
    $(this.selector).filter(':visible').each((_, input) => { sum += parseFloat($(input).val()); })
    this.dom.trigger('simple-summer:beforeRefresh')
    if(this.dom[0].tagName.toLowerCase() == 'input'){
      this.dom.val(sum)
    } else {
      this.dom.text(sum)
    }

    this.dom.trigger('simple-summer:afterRefresh')
  }

  bindEvent() {
    let $field = this.dom
    let selector = $field.data('source')
    let _that = this
    $(document).on('change', selector, (_e) => { _that.refresh(); })
    $field.on('simple-summer:refresh', (_e) => { _that.refresh(); })
  }
}

$.fn.simpleSummer = function() {
  $(this).each(function(_i, el) {
    $el = $(el)
    if($el.data('simple-summer')) return;

    var loaded = new SimpleSummer(el)
    $el.data('simple-summer', loaded)
  })
}