export class DashboardReimbursementForm {
  constructor(dom) {
    this.dom = $(dom)
    this.submitButton = this.dom.find('[data-role=submit]')
    this.modal = this.dom.parents('#reimbursementModal')
    this.bindEvents()
  }

  bindEvents(){
    this.modal.on('hide.bs.modal', (e) => { this.reset(false) })
    this.dom.on('sf:form:response', (e, data) => { this.onResponse(data) })
  }

  onResponse(res) {
    this.resetSubmitButton()
    if(res.success) { this.onSuccess(res)}
    else { this.onError(res) }
  }

  onSuccess(res) {
    console.log('onSuccess', res)
    $('#dashboard').trigger('dashboard.reimbursement.insert', { reimbursement: res.reimbursement })
    let keepContinue = this.dom.find('#continue').prop('checked')
    this.reset(keepContinue)
    if(!keepContinue) { this.modal.modal('hide') }
  }

  onError(res) {
    for(let key in res.errors) {
      if(key == 'base'){
        this._snackbar(res.errors['base'])
      } else {
        this._addFieldError(key, res.errors[key])
      }
    }
  }

  reset(keep = false){
    this.dom[0].reset()
    this.dom.find('.select2').trigger('change.select2')
    this.dom.find('#continue').prop('checked', keep)
    this.dom.find('[data-input=purpose_id]').trigger('change')
    this.resetSubmitButton()
    this._resetError()
  }

  resetSubmitButton(_delay = 100){
    this.submitButton.prop('disabled', false)
    this.dom.find('input[type=file]').removeAttr('disabled')
  }

  _snackbar(message, duration = 3000) {
    $("#snackbar-message").attr("class", "snackbar")
    $("#snackbar-message").find(".snackbar-body").html(message)
    $("#snackbar-message").addClass('show');
    setTimeout(function(){ $("#snackbar-message").removeClass('show'); }, duration)
  }

  _resetError(){
    this.dom.find('[data-input].is-invalid').removeClass('is-invalid')
  }

  _addFieldError(inputName, message) {
    let $inputField = this.dom.find(`[data-input=${inputName}`)
    $inputField.parent().find('.invalid-feedback').remove()
    $inputField.parent().addClass('form-group-invalid')
    $inputField.addClass('is-invalid')
    let feedback = $(`<div class="invalid-feedback">${message}</div>`)
    $inputField.after(feedback)
  }
}
