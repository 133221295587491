class NestedField {
  constructor(element) {
    this.dom = $(element)
    let containerSelector = this.dom.data('target') || '[data-role=container]'
    this.fieldsContainer = this.dom.find(containerSelector)
    this.bindEvent()
    this.refreshIndex()
  }
  bindEvent() {
    let _this = this
    let addSelector = this.dom.data('add-selector') || '[data-action=add]'
    let delSelector = this.dom.data('del-selector') || '[data-action=delete]'
    this.dom.on('click', addSelector, function(e) { _this.addField(e) })
    this.dom.on('click', delSelector, function(e) { _this.deleteField(e) })
  }
  addField(event) {
    event.preventDefault()

    let $btn = $(event.currentTarget)
    let associationName = $btn.data('association')
    let newId = new Date().getTime()
    let regex = new RegExp("new_" +associationName, "g")
    let content

    if($btn.data('target')) {
      let target = this.dom.find($btn.data('target')).first().clone()
      content = $('<div />').append(target).html()
    } else {
      content = $('<textarea />').html($btn.data('content')).val()
    }

    this.dom.trigger('nested-field:beforeAdd', { source: $btn })
    this.fieldsContainer.append(content.replace(regex, newId))
    let field = this.fieldsContainer.find('[data-role=field]').last()
    this.dom.trigger('nested-field:afterAdd', { source: $btn, field: field })
    this.refreshIndex()
  }
  deleteField(event) {
    event.preventDefault()
    let _this = this
    let $btn = $(event.currentTarget)
    let $field = $btn.closest('[data-role=field]')
    let $input = $field.find('[data-input=delete]')

    if($input.length > 0){
      $input.val(1)
      $field.fadeOut(function(){
        _this.dom.trigger('nested-field:afterDelete')
        _this.refreshIndex()
      })
    } else {
      $field.fadeOut(function(){
        $field.remove()
        _this.dom.trigger('nested-field:afterDelete')
        _this.refreshIndex()
      })
    }
  }

  refreshIndex() {
    this.fieldsContainer.find('> [data-role=field]:visible').each((idx, elm) => {
      $(elm).find('[data-role=nested-index]').text(idx+1)
    })
  }
}

$.fn.nestedField = function() {
  $(this).each(function(_i, el) {
    $el = $(el)
    if($el.data('nested-field')) return;

    var loaded = new NestedField(el)
    $el.data('nested-field', loaded)
  })
}