import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['selected', 'remark']
  initialize() {
    this.change()
  }

  change() {
    const source = this.selectedTarget
    const remark = this.remarkTarget
    if(source.checked){
      remark.removeAttribute('disabled')
    } else {
      remark.setAttribute('disabled', '')
    }
  }
}