import {PaymentRemittanceField, CustomPaymentDateField} from './ApplicationField';

export class PaymentApplicationForm {
  constructor(dom) {
    this.dom = $(dom)
    this.nestedFields = this.dom.find('[data-toggle=nested-field').first()
    this.itemContainer = this.dom.find('[data-role=item-container]').first()
    this.candidateContainer = this.dom.find('[data-role=candidate-container]').first()
    this.exchangeRateInput = this.dom.find('[data-input=exchange-rate]')
    this.customExchangeRateInput = this.dom.find('[data-input=custom-exchange-rate]')
    this.bankSlip = this.dom.find('[data-role=bank-slip]')
    this.currencyInput = this.dom.find('[data-role=payment-application] [data-input=currency]')
    this.defaultExchangeRate = this.currencyInput.data('json')
    this.paymentMethodInput = this.dom.find('[data-input=payment_method_id]').first()
    this.mainCurrency = this.dom.data('main-currency')

    this.settlementAmountInput = this.dom.find('[data-input=settlement-amount').first()
    this.adjustmentAmountInput = this.dom.find('[data-input=adjustment-amount]').first()
    this.summer = this.dom.find('[data-toggle=simple-summer]')

    // ApplicationField: paymentRemittance
    let paymentRemittanceFieldGroup = this.dom.find('[data-field-group=payment_remittance]').first()
    if(paymentRemittanceFieldGroup) {
      this.fieldPaymentRemittance = new PaymentRemittanceField(paymentRemittanceFieldGroup)
    }

    // ApplicationField: customPaymentDate
    let customePaymentDateFieldGroup = this.dom.find('[data-field-group=custom_payment_date]').first()
    if(customePaymentDateFieldGroup) {
      this.fieldCustomPaymentDate = new CustomPaymentDateField(this, customePaymentDateFieldGroup)
    }

    this.bindEvents()
    this.toggle()
    this.refreshCandidates()
    let _this = this
    this.itemContainer.find('[data-role=field]').each(function(){
      new PaymentApplicationItemField($(this), _this)
    })
  }

  addExpenseItem(expenseItem) {
    let object = expenseItem.data('json')
    let existedHiddenItem = this.itemContainer.find(`[data-role=field][data-source_id=${object.id}]`)[0]

    if(existedHiddenItem) {
      $(existedHiddenItem).show()
    } else {
      this.currentExpenseItem = expenseItem
      this.nestedFields.find('[data-action=add]').trigger('click')
    }
  }

  fillCurrentExpenseItem(field) {
    let $field = new PaymentApplicationItemField(field, this)

    if(this.currentExpenseItem) {
      let object = this.currentExpenseItem.data('json')
      $field.setExpenseItem(object)
      this.currentExpenseItem = null
    }
  }
  bindEvents() {
    let _this = this
    // select tag not suitable for readonly attribute, so remove disable before submit to send value out
    this.dom.on('submit', function () {
      $(this).find(':input').prop('disabled', false);
    });
    this.nestedFields.on('nested-field:afterAdd', function(e, data){
      _this.fillCurrentExpenseItem(data.field)
      _this.refreshCandidates()
    })
    this.nestedFields.on('nested-field:afterDelete', function(e, data){
      _this.refreshCandidates()
      _this.summer.trigger('simple-summer:refresh')
    })
    this.candidateContainer.on('click', '[data-role=item]:not(.disabled) [data-action=add]', function(e) {
      let item = $(e.currentTarget).closest('[data-role=item]')
      _this.addExpenseItem(item)
    })

    this.currencyInput.on('change', () => { _this.toggleCurrency() })
    this.customExchangeRateInput.on('change', () => { _this.toggleCustomExchangeRate() })
    this.paymentMethodInput.on('change', () => { _this.togglePaymentMethod() })
    this.settlementAmountInput.on('change', () => { _this.toggleSettlement() })
    this.summer.on('simple-summer:afterRefresh', () => { _this.toggleSettlement() })
  }

  refreshCandidates() {
    let _this = this
    this.candidateContainer.find('[data-role=item][data-source_id]').removeClass('disabled')
    this.candidateContainer.find('[data-role=item][data-source_id] .btn').removeClass('disabled')
    this.itemContainer.find('[data-role=field]:visible').each(function(idx){
      $el = $(this)
      let expenseItemId = $el.data('expense_item_id')
      if(!expenseItemId) return
      _this.candidateContainer.find(`[data-role=item][data-source_id=${expenseItemId}]`).addClass('disabled')
      _this.candidateContainer.find(`[data-role=item][data-source_id=${expenseItemId}] .btn`).addClass('disabled')
    })
  }

  toggle() {
    this.toggleCustomExchangeRate()
    this.togglePaymentMethod()
    this.toggleCurrency()
    this.toggleSettlement()
  }

  toggleCustomExchangeRate() {
    if(!this.defaultExchangeRate) { return ; }

    let isCustomExchangeRate = this.isCustomExchangeRate()
    this.exchangeRateInput.prop('disabled', !isCustomExchangeRate)
    this.bankSlip.toggle(this.isCustomExchangeRate())

    if(!isCustomExchangeRate) {
      let exchangeRate = this.defaultExchangeRate[this.currencyInput.val()]
      this.exchangeRateInput.val(exchangeRate).trigger('change')
    }
  }

  togglePaymentMethod() {
    this.toggleCustomPaymentDate()

    let code = this.getCurrentPaymentMethodCode()
    $('[data-toggle=payment_method]').hide()
    if(code == '') { return }

    $(`[data-code=${code}]`).show()

    if(code == 'petty_cash') {
      $(`[data-code=normal]`).show()
      this.fieldPaymentRemittance.pettyCashMode()
    } else if (code == 'normal') {
      this.fieldPaymentRemittance.vendorMode()
    }

    this.toggleRemittanceFieldReadonly()
    this.toggleRemittanceCustomField()
  }

  toggleCurrency(){
    let currency = this.currencyInput.val()
    if(currency == '' || !currency) { return }

    let exchangeRate = this.defaultExchangeRate[this.currencyInput.val()]
    this.exchangeRateInput.val(exchangeRate).trigger('change')
    this.exchangeRateInput.data('currency', currency)

    let currencyName
    if(this.currencyInput[0].tagName == 'INPUT'){
      currencyName = this.currencyInput.val()
    } else {
      currencyName = this.currencyInput.find(`option[value=${currency}]`).text()
    }
    this.dom.find('[data-value=currency-name]').text(currencyName)
  }

  toggleRemittanceCustomField() {
    if(this.fieldPaymentRemittance) {
      this.fieldPaymentRemittance.toggleCustomField()
    }
  }

  toggleRemittanceFieldReadonly() {
    if(this.fieldPaymentRemittance) {
      this.fieldPaymentRemittance.toggleReadonly()
    }
  }

  toggleCustomPaymentDate() {
    if(this.fieldCustomPaymentDate) {
      this.fieldCustomPaymentDate.toggle()
    }
  }

  toggleSettlement() {
    let appliedAmount = parseFloat(this.dom.find('[data-input=prepaid-amount]').val())
    let settlementAmount = parseFloat(this.dom.find('[data-input=settlement-amount]').val())
    let adjustmentAmount = settlementAmount - appliedAmount

    this.adjustmentAmountInput.val(adjustmentAmount)
    this.dom.find('[data-input-group=adjustment]').toggle((adjustmentAmount != 0))
    this.dom.find('[data-field=adjustment_paid_on]').toggle((adjustmentAmount > 0))
  }

  getCurrentPaymentMethodCode() {
    if(this.paymentMethodInput.length == 0 ) { return '' }
    let input = this.paymentMethodInput[0]
    let code
    let value = $(input).val()
    if(value == '') { return '' }

    if(input.tagName.toUpperCase() == 'SELECT') {
      let option = $(input).find(`option[value=${value}]`).first()
      code = option.data('code')
    } else {
      code = $(input).data('code')
    }
    return code
  }

  isCustomExchangeRate() {
    return this.customExchangeRateInput.prop('checked')
  }
}

export class PaymentApplicationItemField {
  constructor(dom, parent) {
    this.dom = $(dom)
    this.parent = parent
    this.mainCurrency = parent.mainCurrency
    this.currencyInput = this.dom.find('[data-input=currency]')
    this.dom.find('[data-input=receipt_date]').pickdate(
      {
        format: 'yyyy-mm-dd',
        formatSubmit: 'yyyy-mm-dd'
      }
    )
    let _this = this
    this.dom.on('change', '[data-input=currency]', (event) => { _this.toggleCurrency() })
    this.dom.find('[data-input=currency').exchangeRate();
    this.toggle()
  }
  setExpenseItem(object) {
    this.dom.find('[data-input=content]').val(object.content)
    this.dom.find('[data-input=amount_in_currency]').val(object.amount_in_currency)
    this.dom.find('[data-input=amount]').val(object.amount)
    this.dom.find('[data-input=currency]').val(object.currency)
    this.dom.find('[data-input=purpose_name]').val(object.purpose_name)
    this.dom.find('[data-input=purpose_id]').val(object.purpose_id)
    this.dom.find('[data-input=expense_item_id]').val(object.id)
    this.dom.data('expense_item_id', object.id)
    this.toggle()
  }
  toggle(){
    let isExpenseConnected = !!this.dom.data('expense_item_id')
    this.dom.find('[data-input=expense_purpose_name]').toggle(isExpenseConnected)
    this.dom.find('[data-input=purpose_id]').toggle(!isExpenseConnected)
    this.toggleCurrency()
  }
  toggleCurrency() {
    let currency = this.currencyInput.val()
    if(currency == '') { return }

    let usingMainCurrency = currency == this.mainCurrency
    let currencyName
    if(this.currencyInput[0].tagName == 'INPUT'){
      currencyName = this.currencyInput.val()
    } else {
      currencyName = this.currencyInput.find(`option[value=${currency}]`).text()
    }

    this.dom.find('[data-value=currency-name]').text(currencyName)
    this.dom.find('[data-role=inCurrency]').data('currency', currency)
    if(usingMainCurrency) {
      this.copyAmount()
    } else {
      this.dom.find('[data-input=amount_in_currency]').trigger('change')
    }
  }
  copyAmount() {
    let value = this.dom.find('[data-input=amount_in_currency]').val()
    this.dom.find('[data-input=amount]').val(value)
  }
}

export class PrepaidPaymentApplicationForm extends PaymentApplicationForm {}
export class PaymentBudgetApplicationForm extends PaymentApplicationForm {}
export class PaymentBudgetSettlementForm extends PaymentApplicationForm {}
export class PaymentSettlementForm extends PaymentApplicationForm {}