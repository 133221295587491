import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'numberOfNights',
    'lodgingPriceMaximum',
    'lodgingPriceInCurrency',
    'allowance',
    'currency',
    'exchangeRate'
  ]
  static values = {
    lodgingBudget: Number,
    allowanceBudget: Number,
    exchangeRates: Object
  }

  initialize(){
    this.number_of_nights()
    this.currency()
  }
  number_of_nights(){
    console.log('triggered')
    let nights = parseInt(this.numberOfNightsTarget.value)
    let days = nights + 1
    this.lodgingPriceMaximumTarget.value = nights * this.lodgingBudgetValue
    this.allowanceTarget.value = days * this.allowanceBudgetValue
  }
  currency() {
    let currency = this.currencyTarget.value
    let rate = this.exchangeRatesValue[currency]
    if(rate){
      this.exchangeRateTarget.value = rate
      $(this.lodgingPriceInCurrencyTarget).trigger('currency:change')
    }
  }
}