export class TravelBudgetForm {
  constructor(dom) {
    this.dom = $(dom)

    this.inputCurrency = this.dom.find('[data-input=currency]')
    this.inputExchangeRate = this.dom.find('[data-input=exchange_rate]')
    this.inputLodgingPrice = this.dom.find('[data-input=lodging_price_in_currency]')
    this.inputNumberOfNights = this.dom.find('[data-input=number_of_nights]')

    this.defaultExchangeRate = this.inputCurrency.data('json')
    this.allowanceBudget = parseInt(this.inputCurrency.data('allowance'))
    this.lodgingBudget = parseInt(this.inputCurrency.data('lodging'))
    this.bindEvents()

    this.toggleAll()
  }

  toggleAll() {
    this.toggleCurrency()
    this.toggleNumberOfNights()
  }

  bindEvents() {
    let _that = this
    this.inputCurrency.on('change', () => _that.toggleCurrency() )
    this.inputLodgingPrice.on('change', () => _that.toggleCurrency() )
    this.inputNumberOfNights.on('change', () => _that.toggleNumberOfNights() )
  }

  toggleCurrency() {
    let rate = this.defaultExchangeRate[this.inputCurrency.val()]
    if(rate) {
      this.inputExchangeRate.val(rate)
      this.inputLodgingPrice.trigger('currency:change')
    }
  }

  toggleNumberOfNights() {
    let nights = parseInt(this.inputNumberOfNights.val())
    let days = nights + 1
    this.dom.find('[data-input=lodging_price_maximum]').val(nights * this.lodgingBudget)
    this.dom.find('[data-input=allowance]').val(days * this.allowanceBudget)
  }
}

export class TravelQuotationForm extends TravelBudgetForm {}