import {FieldForm} from './FieldForm';
export class FieldMetadataForm {
  constructor(editor, dom) {
    this.formEditor = editor
    this.dom = dom
    this.body = dom.find('[data-role=editor-document]')
    let that = this
    this.dom.on('submit', 'form[data-role=form]', function(event) { that.onSubmit(event) })
    this.editObject = null
  }

  load($field) {
    this.editObject = $field
    this.fieldObject = $field.data('json')
    this.fieldObject.field_id = $field.data('id')
    let url = this.formEditor.fieldMetadataUrl
    let that = this
    let data = { metadata: this.fieldObject }

    if(this.formEditor.formId) {
      data.form_id = this.formEditor.formId
    }

    this.body.html('Loading')
    $.ajax({
      type: 'GET',
      url: url,
      data: data,
      dataType: 'html'
    }).done(function(html){
      that.body.html(html)
      new FieldForm(that.body.find('[data-form=Field]'))
    })
  }

  open() {
    this.dom.modal('show')
  }

  close() {
    this.dom.modal('hide')
    this.body.html('Loading')
  }

  onSubmit(event) {
    event.preventDefault()
    let $form = this.dom.find('form[data-role=form]')
    let url = $form.attr('action')
    let data = $form.serialize()
    let that = this
    $.post(url, data, function(res){
      that.formEditor.refreshField(that.editObject, res)
      that.close()
    })
  }
}