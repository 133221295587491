export class ReimbursementApplicationForm {
  constructor(dom) {
    this.dom = $(dom)
    this.nestedFields = this.dom.find('[data-toggle=nested-field').first()
    this.bindEvents()
  }

  bindEvents() {
    this.nestedFields.on('nested-field:afterAdd', function(e, data){
      $(data.field).find('.datepicker').pickdate(
        {
          format: 'yyyy-mm-dd',
          formatSubmit: 'yyyy-mm-dd'
        }
      )
      $(data.field).find('select.select2').select2()
      $(data.field).find('.receipt-date').pickdate(
        {
          format: 'yyyy-mm-dd',
          formatSubmit: 'yyyy-mm-dd',
          max: true,
        }
      )
    })
  }
}