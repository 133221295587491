import {Condition} from './flow-editor/Condition';
import {PayloadFieldGroup} from './flow-editor/PayloadFieldGroup';

export class FlowEditorForm {
  constructor(dom) {
    this.dom = $(dom)
    this.stepsNestedFields = this.dom.find('[data-role=steps-nested-fields').first()
    this.stepsContainer = this.dom.find('[data-role=steps-container]').first()

    let $payloadFields = this.dom.find('[data-field-group=flow-payload]')
    this.payloadFieldGroup = new PayloadFieldGroup($payloadFields)
    this.bindEvents()
    this.stepsContainer.find('> [data-role=field]').each((_idx, elm) => {
      let step = new FlowStep($(elm))
      $(elm).data('flow-step', step)
    })

    let obj = this.stepsNestedFields.data('nested-field')
    if(obj) { obj.refreshIndex() }

    $(this.stepsContainer).sortable({
      update: (e, ui) => {
        obj.refreshIndex()
      }
    })

    this.dom.on('click', '.card-header', (e)=>{
      $(e.currentTarget).next().collapse('toggle')
    })

    this.payloadFieldGroup.toggle()
  }

  bindEvents() {
    let _this = this
    this.stepsNestedFields.on('nested-field:afterAdd', function(e, data){
      let $field = $(data.field)
      let step = new FlowStep($field)
      $field.data('flow-step', step)
      let obj = _this.stepsNestedFields.data('nested-field')
      obj.refreshIndex()
    })
  }
}

export class FlowStep {
  constructor(dom) {
    this.dom = $(dom)
    this.dom.find('[data-toggle=nested-field]').nestedField()
    this.dom.find('[data-toggle=nested-field]').on('nested-field:afterAdd', (_e, data) => {
      let $field = $(data.field)
      let cond = new Condition($field)
      $field.data('condition', cond)
    })
    this.dom.find('[data-role=conditions-container] > [data-role=field]').each((idx, elm) => {
      new Condition($(elm))
    })

    let $payloadFields = this.dom.find('[data-field-group=step-payload]')
    this.payloadFieldGroup = new PayloadFieldGroup($payloadFields)

    this.dom.find('.select2').select2()
    this.selectType = this.dom.find('[data-input=type]')
    this.inputAssessmentOverride = this.dom.find('[data-input=override]')
    this.toggle()

    const _this = this
    this.selectType.on('change', () => { _this.toggleType() })
    this.inputAssessmentOverride.on('change', () => { _this.toggleAssessment() })
  }
  toggle(){
    this.toggleType()
    this.toggleAssessment()
    this.payloadFieldGroup.toggle()
  }

  toggleType() {
    let type = this.selectType.val()
    let $option = this.selectType.find(`[value=${type}]`).first()
    let enabledAssessment = $option.data('assessment') != 'disabled'
    let enabledPolicy = $option.data('assessment') == 'disabled'
    this.dom.find('[data-toggle=assessment]').toggle(enabledAssessment)
    this.dom.find('[data-toggle=assessment]').find('input,select').prop('disabled', !enabledAssessment)
    this.dom.find('[data-toggle=policy]').toggle(enabledPolicy)
    this.dom.find('[data-toggle=policy]').find('input,select').prop('disabled', !enabledPolicy)
  }

  toggleAssessment() {
    let isOverride = this.inputAssessmentOverride.prop('checked')
    this.dom.find('[data-toggle=override]').toggle(isOverride)
    this.dom.find('[data-toggle=override]').find('input,select').prop('disabled', !isOverride)
  }
}