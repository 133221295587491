export class PayloadFieldGroup {
  constructor($dom) {
    this.dom = $dom
    this.inputResource = this.dom.find('[data-input=resource]')
    this.bindEvent()
  }

  bindEvent() {
    let that = this
    this.inputResource.on('change', () => { that.toggleResource() })
  }

  toggle() {
    this.toggleResource()
  }

  toggleResource() {
    let value = this.inputResource.val()
    let option = this.inputResource.find(`option[value="${value}"]`).first()
    let isCustom = option.data('custom')
    this.dom.find('[data-toggle=custom-form]').toggle(isCustom)
    this.dom.find('[data-toggle=predefined-form]').toggle(!isCustom)
  }
}