var GroupField;
GroupField = (function() {
  function GroupField(dom, options) {
    this.options = options;
    this.dom = $(dom);
    this.groupFieldName = this.dom.data('field');
    this.dom.on('click', "[data-group=" + this.groupFieldName + "][data-action=gf-add]", (function(_this) {
      return function(e) {
        return _this.addField(e);
      };
    })(this));
    this.dom.on('click', '[data-action=gf-delete]', (function(_this) {
      return function(e) {
        return _this.deleteField(e);
      };
    })(this));
    this.dom.on('click', '[data-action=gf-copy]', (function(_this) {
      return function(e) {
        return _this.copyField(e);
      };
    })(this));
    if (this.dom.data('rows-max') != undefined) {
      this.maximum = parseInt(this.dom.data('rows-max'));
    }
    if (this.dom.data('rows-min') != undefined) {
      this.minimum = parseInt(this.dom.data('rows-min'));
    }
    this.container = this.dom.find("[data-group=" + this.groupFieldName + "][data-role=container]");

    this.toggleRowLimitation()
  }

  GroupField.prototype.toggleRowLimitation = function(e) {
    let rowsCount = this.container.find('[data-role=field]:visible').length
    let disabledAdd = (this.maximum != undefined) && rowsCount >= this.maximum
    let disabledDelete = (this.minimum != undefined) && rowsCount <= this.minimum
    this.dom.find("[data-group=" + this.groupFieldName + "][data-action=gf-add]").toggle(!disabledAdd)
    this.dom.find('[data-action=gf-delete]').toggle(!disabledDelete)
  }

  GroupField.prototype.addField = function(e) {
    var $btn, $container, $dom, association, content, field, new_id, regexp;
    e.preventDefault();
    $btn = $(e.currentTarget);
    $dom = $(e.delegateTarget);
    $container = this.container;
    if (this.maximum && $container.find('[data-role=field]').length >= this.maximum) {
      this.dom.trigger('gf:violation', {
        type: 'maximum',
        value: this.maximum
      });
      return;
    }
    association = $btn.data('association');
    new_id = new Date().getTime();
    regexp = new RegExp("new_" + association, "g");
    content = $btn.data('content');
    field = $('<div />').html(content).text().replace(regexp, new_id);
    $dom.trigger('gf:beforeAdd', { source: e.currentTarget });
    $container.append(field);
    $container.children().last().find('[data-toggle=group-field]').groupField();
    let lastField = $container.children().last()
    $dom.trigger('gf:afterAdd', { source: e.currentTarget, field: lastField });
    this.toggleRowLimitation()
    return this.refreshRowNumbers();
  };

  GroupField.prototype.copyField = function(e) {
    var $btn, $container, $copyButton, $dom, $source, association, content, new_id, regexp, target;
    e.preventDefault();
    $copyButton = $(e.currentTarget);
    $dom = $(e.delegateTarget);
    $container = this.container;
    if (this.maximum && $container.find('[data-role=field]').length >= this.maximum) {
      this.dom.trigger('gf:violation', {
        type: 'maximum',
        value: this.maximum
      });
      return;
    }
    $source = $copyButton.closest('[data-role=field]');
    $btn = $dom.find('[data-action=add]');
    association = $btn.data('association');
    new_id = new Date().getTime();
    regexp = new RegExp("new_" + association, "g");
    content = $btn.data('target') ? (target = $dom.find($btn.data('target')).first().clone(), $('<div />').append(target).html()) : $('<textarea />').html($btn.data('content')).val();
    content = content.replace(regexp, new_id);
    $source.after(content);
    $dom.trigger("gf:afterCopy", {
      target: e.currentTarget,
      source: $source,
      object: $source.next()
    });
    this.toggleRowLimitation()
    return this.refreshRowNumbers();
  };

  GroupField.prototype.deleteField = function(e) {
    var $btn, $destroy, $dom, $row;
    e.preventDefault();
    $dom = $(e.delegateTarget);
    $btn = $(e.currentTarget);
    $destroy = $btn.parent().find('[data-role=destroy]');
    if (this.minimum && this.container.find('[data-role=field]').length <= this.minimum) {
      this.dom.trigger('gf:violation', {
        type: 'minimum',
        value: this.minimum
      });
      return;
    }
    $row = $btn.closest('[data-role=field]');
    if ($destroy.length > 0) {
      $destroy.val(1);
      return $row.fadeOut((function(_this) {
        return function() {
          $dom.trigger('gf:afterRemove');
          _this.toggleRowLimitation()
          return _this.refreshRowNumbers();
        };
      })(this));
    } else {
      return $row.fadeOut((function(_this) {
        return function() {
          $row.remove();
          $dom.trigger('gf:afterRemove');
          _this.toggleRowLimitation()
          return _this.refreshRowNumbers();
        };
      })(this));
    }
  };

  GroupField.prototype.refreshRowNumbers = function() {
    return this.container.find('[data-role=rownumber]:visible').each(function(idx, row) {
      return $(row).html(idx + 1);
    });
  };

  return GroupField;

})();
$.fn.groupField = function(option) {
  return this.each(function() {
    var $that, data;
    $that = $(this);
    data = $that.data('group-field');
    if (!data) {
      return new GroupField(this, option);
    }
  });
};
